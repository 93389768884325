import siteOptions from "../siteconfigs/index";
import { getToken, getLang } from "../hooks/login/index";
import { headerKeys } from "../consts/index";
import { showErrorMsg } from "../api/errorHandlers";
import urlHelper from './urlHelper';

const isApiHost = function (url) {
	if (url) {
		if (siteOptions.baseURL) {
			return url.toLowerCase().indexOf(siteOptions.baseURL.toLowerCase()) == 0;
		}
		//todo: 需要更全面的判定，但当前项目不需要
	}
	return false;
};

const triggerDownload = (blobData, fileName) => {
	var a = document.createElement('a');
	var url = window.URL.createObjectURL(blobData);
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}

export const getBlob = async (urlOrPath) => {
	return new Promise((resolve, reject) => {

		let url = /^(blob|http|\/\/)/i.test(urlOrPath)
			? urlOrPath
			: urlHelper.combineURLs(window.location.origin, urlOrPath);
		let req = new window.XMLHttpRequest();
		if (req.mock && window._XMLHttpRequest) {
			req = new window._XMLHttpRequest();
		}
		req.responseType = "blob";
		req.addEventListener("error", () => {
			showErrorMsg();
			reject(req);
			// Since we don't have a pdf document available, we will stop the print job
		});

		req.addEventListener("load", () => {
			// Check for errors
			if ([200, 201].indexOf(req.status) === -1) {
				showErrorMsg();
				reject(req);
				// Since we don't have a pdf document available, we will stop the print job
				return;
			}
			if (req.response.type.toLowerCase().indexOf("application/json") > -1) {
				let reader = new FileReader();
				reader.onload = event => {
					//读取之后进行操作的代码区域，event.currentTarget.result 指读取到的内容
					let result = JSON.parse(event.currentTarget.result)
					showErrorMsg(result);
					reject(result);
				}
				//调用方法读取
				reader.readAsText(req.response);
				return;
			}
			resolve(req.response, req);
		});
		req.open("GET", url, true);

		if (isApiHost(url)) {
			let token = getToken();
			if (token) {
				req.setRequestHeader(
					headerKeys.authorization,
					headerKeys.bearer + " " + token
				);
			}

			let culture = getLang();
			if (culture) {
				req.setRequestHeader(headerKeys.customCulture, culture);
			}
		}
		req.send();
	});
}

export const postBlob = async (urlOrPath, postData) => {
	return new Promise((resolve, reject) => {
		let url = /^(blob|http|\/\/)/i.test(urlOrPath)
			? urlOrPath
			: urlHelper.combineURLs(window.location.origin, urlOrPath);
		let req = new window.XMLHttpRequest();
		if (req.mock && window._XMLHttpRequest) {
			req = new window._XMLHttpRequest();
		}
		req.responseType = "blob";
		req.addEventListener("error", () => {
			showErrorMsg();
			reject(req);
		});

		req.addEventListener("load", () => {
			// Check for errors
			if ([200, 201].indexOf(req.status) === -1) {
				showErrorMsg();
				reject(req);
				return;
			}
			// Print requested document
			if (req.response.type.toLowerCase().indexOf("application/json") > -1) {
				let reader = new FileReader();
				reader.onload = event => {
					// 读取之后进行操作的代码区域，event.currentTarget.result 指读取到的内容
					let result = JSON.parse(event.currentTarget.result)
					showErrorMsg(result);
					reject(result);
				}
				//调用方法读取
				reader.readAsText(req.response);
				return;
			}
			resolve(req.response, req);
		});
		req.open("POST", url, true);
		req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
		if (isApiHost(url)) {
			let token = getToken();
			if (token) {
				req.setRequestHeader(
					headerKeys.authorization,
					headerKeys.bearer + " " + token
				);
			}

			let culture = getLang();
			if (culture) {
				req.setRequestHeader(headerKeys.customCulture, culture);
			}
		}
		req.send(JSON.stringify(postData || {}));
	});
}

export const getObjectUrl = async (urlOrPath, method = "GET", postJsonData = null) => {
	let blobData = null;
	if ((method || "").toUpperCase() == "POST") {
		blobData = await postBlob(urlOrPath, postJsonData);
	} else {
		blobData = await getBlob(urlOrPath);
	}
	return window.URL.createObjectURL(blobData);
}

export const downloadFile = async (urlOrPath, fileName, method = "GET", postJsonData = null) => {
	let blobData = null;
	if ((method || "").toUpperCase() == "POST") {
		blobData = await postBlob(urlOrPath, postJsonData);
	} else {
		blobData = await getBlob(urlOrPath);
	}
	triggerDownload(blobData, fileName);
}