<template>
  <div class="file-display-wrap">
    <template v-if="fileUrl">
      <c-image v-if="urlHelper.isImageUrl(fileUrl)" :preview="!disImgPreview" :src="fileUrl" :dis-preview-process="disImgProcess" :dis-thumb-process="disThumbProcess"/>
      <template v-else>
        <span v-if="disLink" class="icon-link">
          <component :is="$antIcons[iconName]" />
        </span>
        <template v-else>
          <a-spin v-if="useLocal" :spinning="loading">
            <a href="javascript:;" @click="handleClick" class="icon-link">
              <component :is="$antIcons[iconName]" />
            </a>
          </a-spin>
          <a v-else :href="fileUrl" target="_blank" class="icon-link">
            <component :is="$antIcons[iconName]" />
          </a>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
import CImage from "./CImage.vue";
import urlHelper from "../../utils/urlHelper";
import { getObjectUrl } from "../../utils/downloader";
import { Spin } from "ant-design-vue";
import { computed, ref } from "vue";
export default {
  name: "CFileDisplay",
  components: {
    CImage,
    ASpin: Spin,
  },
  props: {
    fileUrl: {
      type: String,
      // required: true,
      default: "",
    },
    disLink: {
      type: Boolean,
      default: false,
    },
    disImgPreview:{
      type: Boolean,
      default: false,
    },
    disImgProcess:{
      type: Boolean,
      default: false,
    },
    disThumbProcess:{//缩略图是是否显示为处理后(阿里云小)的尺寸
      type: Boolean,
      default: false,
    },
    useLocal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const iconName = computed(() => {
      if (urlHelper.isPdfUrl(props.fileUrl)) {
        return "FilePdfOutlined";
      }
      if (urlHelper.isExcelUrl(props.fileUrl)) {
        return "FileExcelOutlined";
      }
      if (urlHelper.isWordUrl(props.fileUrl)) {
        return "FileWordOutlined";
      }
      if (urlHelper.isTxtUrl(props.fileUrl)) {
        return "FileTextOutlined";
      }
      return "LinkOutlined";
    });
    const loading = ref(false);
    
    const handleClick = () => {
      if (loading.value || !props.fileUrl) {
        return;
      }
      loading.value = true;
      getObjectUrl(props.fileUrl)
      .then((url)=>{
        window.open(url);
      })
      .finally(()=>{
      loading.value = false;
      });
    };
    return {
      iconName,
      urlHelper,
      handleClick,
      loading,
    };
  },
};
</script>

<style lang="less">
.file-display-wrap {
  width: 54px;
  height: 54px;
  border: 1px solid #d9d9d9;
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  .ant-image img {
    // max-height: 100% !important;
    max-height: 46px !important;
    width: 100% !important;
  }
  .icon-link {
    font-size: 40px;
    color: @primary-color;
  }
}
</style>
