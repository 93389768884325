<template>
  <a-pagination :show-quick-jumper="showQuickJumper"
                :show-less-items="showLessItems"
                v-model:current="pageData.currentIndex"
                :total="pageData.totalCount"
                @change="handleGotoTargetPage"
                :pageSizeOptions="pageSizeOptions"
                @showSizeChange="handleChangePageSize"
                :showSizeChanger="showSizeChanger"
                :defaultPageSize="pageData.maxResultCount"
                :show-total="total => `${$t('common.total')}${total}${$t('common.item')} `" />
</template>
<script>
import { Pagination } from 'ant-design-vue'

export default ({
  name: "CPager",
  props: {
    pageData:{
      type:Object,
      default:function(){
        return{
          currentIndex:1,
          skipCount:0,
          totalCount:0,
          maxResultCount:10
        }
      }
    },

    showQuickJumper: {
      type: Boolean,
      default: true,
      required: false
    },
    showSizeChanger: {
      type: Boolean,
      default: true,
      required: false
    },
    hideOnSinglePage: {
      type: Boolean,
      default: true,
      required: false
    },
    pageSizeOptions: {
      type: Array,
      default: ['10', '30', '50', '100'],
      required: false
    },
    showLessItems: {//显示少的分页数量
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup (props, { emit }) {


    const handleGotoTargetPage = (page, pageSize) => {
      getData(page, pageSize)
    }

    const handleChangePageSize = (page, size) => {
      getData(page, size)
    }

    const getData = async (currentPage, pageSize) => {
      currentPage = currentPage == 0 ? 1 : currentPage;
      let skipCount = pageSize * (currentPage - 1);
      let pageData = { 'skipCount': skipCount, 'maxResultCount': pageSize,};
      emit('handlePage', pageData)
    }

    return {
      handleGotoTargetPage,
      handleChangePageSize
    }
  },
  components: {
    APagination: Pagination
  }
})
</script>
