<template>
  <a-modal
    width="auto"
    v-model:visible="visible"
    :title="$t('common.details')"
    :centered="true"
    :mask-closable="false"
    :footer="null"
  >
    <a-table
      :columns="innerColumns"
      :data-source="tableList"
      :pagination="false"
      :loading="loading"
      :rowKey="
        (record, index) => {
          return record.id;
        }
      "
      size="small"
    >
      <template #productInfo="{ record }">
        <a-row v-if="record.product" :gutter="8" type="flex" align="middle">
          <a-col>
            <CFileDisplay :fileUrl="record.product.imgUrl" />
          </a-col>
          <a-col flex="1">
            <div>{{ record.product.productName }}</div>
            <div>{{ record.product.productNo }}</div>
          </a-col>
        </a-row>
        <span v-else>-</span>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Col, Modal, Row, Table } from 'ant-design-vue';
import CFileDisplay from '@/views/components/CFileDisplay.vue';
import { useI18n } from "vue-i18n/index";
import { getProductInventoryAdjustmentById } from '@/api/modules/consignment/index.js';

export default defineComponent({
  expose: ['open'],
  components: {
    AModal: Modal,
    ATable: Table,
    ARow: Row,
    ACol: Col,
    CFileDisplay,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      loading: false,
      tableList: [],
    });

    const innerColumns = [
      {
        width: 300,
        title: () => vueI18n.t('warehouse.product_info'),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        dataIndex: "seSku",
        width: 200,
        title: "SESKU",
      },
      {
        dataIndex: "warehousePositionRoute",
        width: 200,
        title: () => vueI18n.t('warehouse.current_location'),
      },
      {
        dataIndex: "currentInventoryCount",
        width: 150,
        title: () => vueI18n.t('warehouse.inventory_remaining_quantity'),
      },
      {
        dataIndex: "changeInventoryCount",
        width: 150,
        title: () => vueI18n.t('warehouse.inventory_adjustment_quantity'),
      },
    ];

    const getDetail = (id) => {
      state.loading = true;
      getProductInventoryAdjustmentById({ id }).then(({ result }) => {
        if (result && Array.isArray(result.records)) {
          state.tableList = result.records;
        } else {
          state.tableList = [];
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      })
    }

    const open = (id) => {
      state.visible = true;
      if (id) {
        getDetail(id);
      }
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      innerColumns,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>